import { Skeleton } from '@ori-ui/mui';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import type { SkeletonProps } from '../types';
import { DESCRIPTION_SKELETON } from './constants';
import { Root } from './styles';

/**
 * Renders Skeleton for `Description`.
 */
export const DescriptionSkeleton: FC<SkeletonProps> = ({ animation = 'pulse', className }) => {
  const { getTestId } = useTestId();
  const isMobile = useIsMobile();

  return (
    <Root
      className={className}
      data-testid={getTestId(DESCRIPTION_SKELETON)}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height={isMobile ? '132px' : '88px'}
        animation={animation}
      />
    </Root>
  );
};
