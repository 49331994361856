import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { getModulesContentAndLengthError } from '../../utils';
import { Button, ImageBox } from '../shared';
import { IMAGE_PARAMS } from './constants';
import { ImageWrapper, Root, TitleWithDesktopMargin, Wrapper } from './styles';

export const TwoMediaLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const isMobile = useIsMobile();
  const { getTestId } = useTestId();
  const { modules, layout, id } = banner;

  if (modules == null || modules.length < 1) {
    return getModulesContentAndLengthError({ debug, layout, modulePosition });
  }

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.TwoMedia)}>
      {modules.map((module, index) => {
        const { title, titleStorybookColor } = module?.content ?? {};
        const link = module?.link;

        return (
          // eslint-disable-next-line react/no-array-index-key -- no unique index
          <Wrapper key={index}>
            <ImageWrapper>
              <ImageBox
                id={id}
                titleMaxLines={isMobile ? 3 : 4}
                layout={layout}
                module={module}
                moduleNumber={index}
                imageParams={IMAGE_PARAMS.params}
                sizes={IMAGE_PARAMS.sizes}
              />
            </ImageWrapper>
            {title ? (
              <TitleWithDesktopMargin
                titleVariant="h5"
                title={title}
                maxLines={2}
                color={titleStorybookColor}
              />
            ) : null}
            <Button link={link} />
          </Wrapper>
        );
      })}
    </Root>
  );
};
