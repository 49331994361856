import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { TextBox } from '../shared';
import { Root } from './styles';

export const ParagraphTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { layout, modules, id } = banner;
  const { getTestId } = useTestId();
  const isMobile = useIsMobile();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.ParagraphText)}>
      <TextBox
        id={id}
        moduleNumber={0}
        titleMaxLines={isMobile ? 5 : 4}
        layout={layout}
        titleVariant="h4"
        module={modules[0]}
      />
    </Root>
  );
};
