import { LinkType } from '@ori/editorial-fetching';
import type { ArticleBoxProps } from '@ori/presentation-components';

import type { MapEditorialCarouselParams } from './types';

/**
 * Utility to filter carousel slides with thruthy data and map them to `ArticleBoxProps` type.
 * @returns Slides with thruthy data mapped to `ArticleBoxProps` type.
 */
export const mapEditorialCarousel = ({
  slides,
}: MapEditorialCarouselParams): Array<Omit<ArticleBoxProps, 'labelRead' | 'labelWatch'>> =>
  (slides ?? [])
    .filter((slide) => !!slide)
    .filter(({ pageId, description, thumbnailUrl, title }) => !!pageId && !!description && !!thumbnailUrl && !!title)
    .map(({ pageId, description, themeTag, thumbnailUrl, title }) => ({
      categoryTitle: themeTag,
      image: { alt: title, url: thumbnailUrl },
      introText: description,
      isVideoArticle: false,
      link: { relativeUrl: pageId, type: LinkType.EditorialArticle },
      title,
    }));
