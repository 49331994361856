import { Button as MuiButton } from '@ori-ui/mui';
import useTestId from '@ori/testid-generator';
import React from 'react';

import { CTA_BUTTON } from './constants';
import { Root } from './styles';
import type { ButtonProps } from './types';

export const Button = ({ link, position = 'center' }: ButtonProps) => {
  const { title, relativeUrl } = link ?? {};
  const { getTestId } = useTestId();

  if (title && relativeUrl) {
    return (
      <Root position={position}>
        <MuiButton
          data-testid={getTestId(CTA_BUTTON)}
          href={relativeUrl}
          variant="outlined"
        >
          {title}
        </MuiButton>
      </Root>
    );
  }

  return null;
};
