/* istanbul ignore file */
import { useEnvironment } from '@ori/environment';
import { useSWRInfinite } from '@ori/fetching/infinite';
import { useLanguage } from '@ori/i18n';
import { useUserSegmentationData } from '@ori/presentation-http';
import { getRetryErrorSwrInfiniteOptions } from '@ori/presentation-utils';
import type { MappedProductBoxProps } from '@ori/product-box';
import { useApiClient, type AxiosError } from '@ori/static-api-client-react';
import { isNonNullable } from '@ori/ts-utils';
import { useMemo, useCallback } from 'react';

import { PRODUCTS_PAGINATION_LIMIT, PRODUCTS_PAGINATION_LIMIT_FIRST_PAGE } from '../constants';
import type { FetchParams, GetEditorialProductsParams } from './getEditorialProducts';
import { getEditorialProducts } from './getEditorialProducts';

export interface UseGetEditorialProductsParams {
  productCodes: string[];
}

type GetKey = (
  pageIndex: number,
  previousPageData: MappedProductBoxProps[] | null,
) => GetEditorialProductsParams | null;

export const useGetEditorialProducts = ({ productCodes }: UseGetEditorialProductsParams) => {
  const { language } = useLanguage();
  const { tenant } = useEnvironment();
  const { loading: loadingSegmentationData, atpClusterId, customerTypeId, visitorSegment } = useUserSegmentationData();
  const staticApiClient = useApiClient();
  const fetchParams = useMemo<FetchParams>(
    () => ({
      lang: language,
      atpClusterId: atpClusterId ?? undefined,
      customerTypeId: customerTypeId ?? undefined,
      visitorSegment: visitorSegment ?? undefined,
    }),
    [atpClusterId, customerTypeId, language, visitorSegment],
  );
  const {
    size,
    isValidating,
    data: fetchedProducts = [],
    setSize,
  } = useSWRInfinite<Array<MappedProductBoxProps | null> | undefined, AxiosError, GetKey>(
    (pageIndex) => {
      if (loadingSegmentationData) {
        return null;
      }

      const paginationLimit = pageIndex === 0 ? PRODUCTS_PAGINATION_LIMIT_FIRST_PAGE : PRODUCTS_PAGINATION_LIMIT;
      const newLoadedRecords =
        pageIndex === 0 ? 0 : PRODUCTS_PAGINATION_LIMIT_FIRST_PAGE + (pageIndex - 1) * PRODUCTS_PAGINATION_LIMIT;
      const newProductCodes = productCodes.slice(newLoadedRecords, newLoadedRecords + paginationLimit);

      return { fetchParams, productCodes: newProductCodes, tenant, staticApiClient };
    },
    getEditorialProducts,
    {
      ...getRetryErrorSwrInfiniteOptions(),
      revalidateFirstPage: false,
    },
  );

  const loadMoreItems = useCallback(async () => {
    await setSize((prevSize) => prevSize + 1);
  }, [setSize]);

  const products = useMemo(() => fetchedProducts.flat().filter(isNonNullable), [fetchedProducts]);

  return useMemo(
    () => ({
      isLoadingMore: size > 0 && isValidating,
      isRefreshing: products.length === 0 && isValidating,
      products,
      loadMoreItems,
    }),
    [isValidating, loadMoreItems, products, size],
  );
};
