/* istanbul ignore file */

/**
 * DO NOT EDIT!
 * This file was autogenerated by npm script `localizations-codegen`.
 * Changes may cause incorrect behavior and will be lost when the file is regenerated.
 *
 * Run `npx localizations-codegen` to regenerate.
 */
import type { TranslationKey } from '../models/TranslationKey';

export type DefaultTranslations = Record<keyof typeof TranslationKey, string>;

export const defaultTranslations: DefaultTranslations = {
  categories: 'Categories',
  inspirationCorner: 'Inspiration corner',
  loadMore: 'Load more',
  otherCategories: 'Other categories',
  readNow: 'Read now',
  relatedArticles: 'Related articles',
  showingXOutOfYProducts: 'Showing {value} out of {max} articles',
  view: 'View',
  whatsNew: "What's new",
};

export const translationsNamespace = 'products_editorial-category';
