import { DefaultLayoutFeature, MyPagesLayoutFeature } from '@ori-appshell/layouts';

const CommonPageFeatures = {
  /** It enable/disable sending search events to analytics. */
  SendSearchEvents: '3767D0FE-0404-4FC8-A552-4B3F2A4036C7',
} as const;

export const PageFeatures = {
  ...DefaultLayoutFeature,
  ...CommonPageFeatures,
} as const;

export const MyPagesPageFeatures = {
  ...MyPagesLayoutFeature,
  ...CommonPageFeatures,
} as const;
