import type { TypographyVariant } from '@ori-ui/mui';
import { theme } from '@ori-ui/mui';
import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { getModulesContentError } from '../../utils';
import { Root, Text } from './styles';

export const QuoteLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { getTestId } = useTestId();

  const { modules, layout } = banner;

  if (!modules?.[0]?.content) {
    return getModulesContentError({ debug, layout, modulePosition });
  }

  const { backgroundStorybookColor, content } = modules[0];
  const { title, titleStorybookColor, titleSize } = content;

  const variantMapping: Record<string, Extract<TypographyVariant, 'h6' | 'h5'>> = {
    H5: 'h5',
    H6: 'h6',
  };

  const variant = variantMapping[titleSize];

  const mappedBackgroundColor = mapBackendColorToTheme({
    backendColor: backgroundStorybookColor,
    theme,
  });
  const mappedTitleColor = mapBackendColorToTheme({
    backendColor: titleStorybookColor,
    fallBackColor: theme.palette.common.black,
    theme,
  });

  return (
    <Root
      data-testid={getTestId(EditorialBannerLayoutEnum.Quote)}
      backgroundColor={mappedBackgroundColor}
      titleColor={mappedTitleColor}
    >
      <Text
        variant={variant}
        component={variant}
        textAlign="center"
      >
        {title}
      </Text>
    </Root>
  );
};
