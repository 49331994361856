import { styled } from '@ori-ui/mui';

import { LEFT_IMAGE_DESKTOP_WIDTH, RIGHT_IMAGE_DESKTOP_WIDTH } from './constants';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  justifyItems: 'center',
  gap: theme.spacing(6),
  padding: 0,
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(8),
  },
}));

export const LeftSide = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    width: LEFT_IMAGE_DESKTOP_WIDTH,
  },
}));

export const RightSide = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    width: RIGHT_IMAGE_DESKTOP_WIDTH,
    gap: theme.spacing(4),
  },

  /** Last element on mobile have to have only 3 gap */
  [theme.breakpoints.down('md')]: {
    '& > *:last-child': {
      marginTop: theme.spacing(-3),
    },
  },
}));

export const RightImage = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: RIGHT_IMAGE_DESKTOP_WIDTH,
  },
}));
