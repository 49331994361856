import { Skeleton } from '@ori-ui/mui';
import type { SkeletonProps } from '@ori/presentation-components';
import type { FC } from 'react';

import { SkeletonRoot } from './styles';

export const EditorialPageSkeleton: FC<SkeletonProps> = ({ animation = 'pulse', className }) => (
  <SkeletonRoot className={className}>
    <Skeleton
      variant="rectangular"
      width="800px"
      height="240px"
      animation={animation}
    />
    <Skeleton
      variant="text"
      width="500px"
      height="120px"
      animation={animation}
    />
    <Skeleton
      variant="text"
      width="200px"
      height="20px"
      animation={animation}
    />
    <Skeleton
      variant="text"
      width="200px"
      height="20px"
      animation={animation}
    />

    <Skeleton
      variant="rectangular"
      width="100%"
      height="500px"
      animation={animation}
    />

    <Skeleton
      variant="rectangular"
      width="800px"
      height="500px"
      animation={animation}
    />

    <Skeleton
      variant="rectangular"
      width="100%"
      height="500px"
      animation={animation}
    />
  </SkeletonRoot>
);
