import type { CarouselProps } from '@ori/presentation-components';
import { Carousel, CarouselSlide } from '@ori/presentation-components';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { useMemo } from 'react';

import { getModulesContentError } from '../../utils';
import {
  TESTIMONIAL_CAROUSEL_AUTHOR,
  TESTIMONIAL_CAROUSEL_DESCRIPTION,
  TESTIMONIAL_CAROUSEL_ID,
  TESTIMONIAL_CAROUSEL_IMAGE,
  TESTIMONIAL_CAROUSEL_INTERVAL,
  TESTIMONIAL_CAROUSEL_SLIDE,
  TESTIMONIAL_CAROUSEL_STARS,
  TESTIMONIAL_CAROUSEL_TITLE,
  TESTIMONIAL_MODULE_NAME,
} from './constants';
import {
  ContentInner,
  ContentWrapper,
  Description,
  Image,
  ImageWrapper,
  NameAndCountry,
  Root,
  StarRating,
  Title,
} from './styles';
import type { TestimonialsLayoutProps } from './types';

export const TestimonialsLayout: FC<TestimonialsLayoutProps> = ({ data, debug, modulePosition }) => {
  const isMobile = useIsMobile();
  const { getTestId } = useTestId();

  const carouselOptions = useMemo<Omit<CarouselProps, 'children'>>(
    () => ({
      navigation: { position: 'inside', showArrows: !isMobile },
      autoplay: {
        enabled: true,
        interval: TESTIMONIAL_CAROUSEL_INTERVAL,
      },
      slider: {
        loop: true,
      },
    }),
    [isMobile],
  );

  if (!data?.collection || data.collection.length < 1) {
    return getModulesContentError({ debug, modulePosition, layout: TESTIMONIAL_MODULE_NAME });
  }

  return (
    <Carousel
      {...carouselOptions}
      id={TESTIMONIAL_CAROUSEL_ID}
    >
      {data.collection.map(({ textCollection, title, image, author, showStarRating }) => {
        const imageUrl = isMobile ? (image?.urlMobile ?? image?.url ?? '') : (image?.url ?? '');
        const hasImage = Boolean(imageUrl);

        return (
          <CarouselSlide key={title}>
            <Root data-testid={getTestId(TESTIMONIAL_CAROUSEL_SLIDE)}>
              {hasImage ? (
                <ImageWrapper>
                  <Image
                    data-testid={getTestId(TESTIMONIAL_CAROUSEL_IMAGE)}
                    src={imageUrl}
                  />
                </ImageWrapper>
              ) : null}
              <ContentWrapper hasImage={hasImage}>
                <ContentInner hasImage={hasImage}>
                  <Title data-testid={getTestId(TESTIMONIAL_CAROUSEL_TITLE)}>{title}</Title>
                  {showStarRating ? (
                    <StarRating
                      data-testid={getTestId(TESTIMONIAL_CAROUSEL_STARS)}
                      max={5}
                      value={5}
                    />
                  ) : null}
                  <Description
                    data-testid={getTestId(TESTIMONIAL_CAROUSEL_DESCRIPTION)}
                    variant="subtitle1"
                  >
                    {textCollection?.[0]?.text}
                  </Description>
                  <NameAndCountry
                    data-testid={getTestId(TESTIMONIAL_CAROUSEL_AUTHOR)}
                    variant="body1"
                  >
                    {author}
                  </NameAndCountry>
                </ContentInner>
              </ContentWrapper>
            </Root>
          </CarouselSlide>
        );
      })}
    </Carousel>
  );
};
