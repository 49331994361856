import { theme, Typography } from '@ori-ui/mui';
import { EditorialBannerLayoutEnum, HorizontalContentAlignment } from '@ori/editorial-fetching';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import type { BannerComponentProps } from '../../common';
import { getModulesContentError } from '../../utils';
import { Button, Title, VideoLayout } from '../shared';
import { BUTTON, CAPTION, CONTENT_AREA, DESCRIPTION } from './constants';
import { ButtonWrapper, Caption, ContentArea, Description, Inner, Root, VideoArea } from './styles';

export const VerticalVideoLayout: FC<BannerComponentProps> = ({ banner, debug, modulePosition }) => {
  const { modules, layout } = banner;
  const { getTestId } = useTestId();
  const firstModule = modules?.[0];

  if (!firstModule) {
    return getModulesContentError({ debug, layout, modulePosition });
  }

  const { backgroundStorybookColor, content, link } = firstModule;
  const {
    title,
    caption,
    titleStorybookColor,
    horizontalAlignment = HorizontalContentAlignment.Left,
    textCollection,
  } = content ?? {};

  const mappedBackgroundColor = mapBackendColorToTheme({
    backendColor: backgroundStorybookColor,
    fallBackColor: theme.palette.primary.main,
    theme,
  });

  return (
    <Root
      data-testid={getTestId(EditorialBannerLayoutEnum.VerticalVideo)}
      backgroundColor={mappedBackgroundColor}
    >
      <Inner>
        <VideoArea alignment={horizontalAlignment}>
          <VideoLayout
            banner={banner}
            modulePosition={modulePosition}
            debug={debug}
          />
        </VideoArea>
        <ContentArea data-testid={getTestId(CONTENT_AREA)}>
          {title ? (
            <Title
              title={title}
              titleVariant="h4"
              maxLines={2}
              color={titleStorybookColor}
            />
          ) : null}
          {caption ? (
            <Caption
              data-testid={getTestId(CAPTION)}
              variant="body1"
            >
              {caption}
            </Caption>
          ) : null}
          <Description data-testid={getTestId(DESCRIPTION)}>
            {textCollection?.map((text, index) => (
              <Typography
                // eslint-disable-next-line react/no-array-index-key -- no suitable key
                key={index}
                variant="body1"
              >
                {text?.text}
              </Typography>
            ))}
          </Description>
          {link ? (
            <ButtonWrapper data-testid={getTestId(BUTTON)}>
              <Button link={link} />
            </ButtonWrapper>
          ) : null}
        </ContentArea>
      </Inner>
    </Root>
  );
};
