import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { Button, ImageBox, Title } from '../shared';
import { IMAGE_PARAMS } from './constants';
import { FourMediaItem, Root } from './styles';

export const FourMediaLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { modules, layout, id } = banner;
  const { getTestId } = useTestId();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.FourMedia)}>
      {modules.map((module, index) => {
        const { title, titleStorybookColor } = module?.content ?? {};
        const link = module?.link ?? null;

        return (
          // eslint-disable-next-line react/no-array-index-key -- no unique index
          <FourMediaItem key={index}>
            <ImageBox
              moduleNumber={index}
              module={module}
              layout={EditorialBannerLayoutEnum.FourMedia}
              id={id}
              imageParams={IMAGE_PARAMS.params}
              sizes={IMAGE_PARAMS.sizes}
            />
            {title ? (
              <Title
                titleVariant="h5"
                title={title}
                maxLines={2}
                color={titleStorybookColor}
              />
            ) : null}
            {link ? <Button link={link} /> : null}
          </FourMediaItem>
        );
      })}
    </Root>
  );
};
