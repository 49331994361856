import { styled, Typography } from '@ori-ui/mui';
import { productListClasses } from '@ori/product-list';

interface RootProps {
  isList: boolean;
}
export const Root = styled('div')<RootProps>(({ theme, isList }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8),
  },
  ...(!isList && {
    [`.${productListClasses.root}`]: {
      maxWidth: 1078,
    },
    [`.${productListClasses.carousel}`]: {
      maxWidth: 1078,
      width: '100%',
    },
  }),
}));

export const Headline = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.grey[900],
}));
