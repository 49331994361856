import { Typography } from '@ori-ui/mui';
import { EditorialCarousel } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { EDITORIAL_CAROUSEL_SECTION, EDITORIAL_CAROUSEL_SECTION_BUTTON } from './constants';
import { CarouselContainer, Inner, Root, StyledButton } from './styles';
import type { EditorialCarouselSectionProps } from './types';

export const EditorialCarouselSection: FC<EditorialCarouselSectionProps> = ({ title, data, labelRead, id, button }) => {
  const { getTestId } = useTestId();
  const { relativeUrl, title: buttonTitle } = button ?? {};
  const shouldDisplayButton = !!relativeUrl && !!buttonTitle;

  if (data.length === 0) {
    return null;
  }

  return (
    <Root data-testid={getTestId(EDITORIAL_CAROUSEL_SECTION)}>
      <Inner>
        <Typography
          variant="h4"
          component="h4"
          align="center"
          color="textPrimary"
        >
          {title}
        </Typography>
        <CarouselContainer>
          <EditorialCarousel
            data={data}
            labelRead={labelRead}
            labelWatch=""
            id={id}
          />
        </CarouselContainer>
      </Inner>
      {shouldDisplayButton ? (
        <StyledButton
          data-testid={getTestId(EDITORIAL_CAROUSEL_SECTION_BUTTON)}
          color="default"
          variant="outlined"
          href={relativeUrl}
          aria-label={buttonTitle}
        >
          {buttonTitle}
        </StyledButton>
      ) : null}
    </Root>
  );
};
