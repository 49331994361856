import { gql } from '@ori/fetching';

export const testimonialModuleFragment = gql`
  fragment TestimonialModule on EditorialTestimonialCarousel {
    __typename
    id
    collection {
      __typename
      title
      showStarRating
      textCollection {
        __typename
        text
        textLinks {
          id
          relativeUrl
          title
        }
      }
      author
      image {
        __typename
        url
        urlMobile
      }
    }
  }
`;
