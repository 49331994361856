import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import { TextWithHtml } from '@ori/presentation-components';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import React from 'react';

import type { BannerComponentProps } from '../../common';
import { getKey, getModulesLengthError, transformHtmlContent } from '../../utils';
import { Button, ImageBox, Title } from '../shared';
import { elementsProps } from '../shared/TextBox/constants';
import { IMAGE_PARAMS } from './constants';
import { ImageWrapper, OneMediaRoot, TextWrapper } from './styles';

export const OneMediaParagraphTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { getTestId } = useTestId();
  const { layout, modules, id } = banner;
  const isMobile = useIsMobile();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  // Can't use TextBox as the link is in another module
  const { link } = modules[0] ?? {};
  const { textCollection, title } = modules[1]?.content ?? {};

  return (
    <OneMediaRoot
      data-testid={getTestId(EditorialBannerLayoutEnum.OneMediaParagraphText)}
      className="split-layout"
    >
      <ImageWrapper>
        <ImageBox
          id={id}
          layout={layout}
          module={modules[0]}
          moduleNumber={0}
          imageParams={IMAGE_PARAMS.params}
          sizes={IMAGE_PARAMS.sizes}
        />
      </ImageWrapper>
      <TextWrapper>
        {title ? (
          <Title
            title={title}
            maxLines={isMobile ? 5 : 4}
            titleVariant={isMobile ? 'h5' : 'h4'}
          />
        ) : null}

        {textCollection && textCollection.length > 0 ? (
          <>
            {textCollection.map((text, index) => (
              <TextWithHtml
                key={getKey('text', index)}
                html={transformHtmlContent(text)}
                elementsProps={elementsProps}
              />
            ))}
          </>
        ) : null}

        {link ? <Button link={link} /> : null}
      </TextWrapper>
    </OneMediaRoot>
  );
};
