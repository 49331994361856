import { gql } from '@ori/fetching';

import { testimonialModuleFragment } from './testimonialModuleFragment';

const editorialPageBannerTextFragment = gql`
  fragment EditorialPageBannerText on EditorialBannerText {
    text
    textLinks {
      title
      relativeUrl
    }
  }
`;

const editorialPageHeaderFragment = gql`
  fragment EditorialPageHeader on EditorialHeader {
    __typename
    authorName
    id
    publishedDate
    isSharingEnabled
    headline
    extraLongHeadline
    text
    imageUrl
    links {
      data {
        __typename
      }
    }
    photographerName
    headlineStorybookColor
    backgroundStorybookColor
  }
`;

const editorialPageOlapicFragment = gql`
  fragment EditorialPageOlapic on EditorialOlapic {
    __typename
    id
    categoryId
    product {
      productCode
    }
    type
  }
`;

const editorialPageProductBoxCarouselFragment = gql`
  fragment EditorialPageProductBoxCarousel on EditorialProductBoxCarousel {
    __typename
    headline
    products {
      productCode
    }
  }
`;

const editorialPageBannerModuleFragment = gql`
  fragment EditorialPageBannerModule on EditorialBannerModule {
    backgroundColor {
      type
    }
    backgroundStorybookColor
    content {
      title
      titleColor
      titleStorybookColor
      titleSize
      caption
      showReadMoreOption
      horizontalAlignment
      textCollection {
        ...EditorialPageBannerText
      }
    }
    backgroundType
    backgroundVideo {
      autoplayOnScroll
      id
      playInLoop
      type
    }
    link {
      relativeUrl
      title
      data {
        __typename
        ... on Product {
          productCode
          labels {
            labelKey
            productLabelDomain
            title
          }
          concept {
            name
            products {
              productCode
            }
          }
          price {
            price {
              currentPrice
            }
          }
        }
      }
    }
    backgroundImage {
      url
    }
  }
`;

const editorialPageBannerFragment = gql`
  ${editorialPageBannerModuleFragment}
  ${editorialPageBannerTextFragment}
  fragment EditorialPageBanner on EditorialBanner {
    __typename
    id
    layout
    modules {
      ...EditorialPageBannerModule
    }
  }
`;

export const editorialArticlePageFragment = gql`
  ${editorialPageHeaderFragment}
  ${editorialPageOlapicFragment}
  ${editorialPageProductBoxCarouselFragment}
  ${editorialPageBannerFragment}
  ${testimonialModuleFragment}
  fragment EditorialArticlePage on Application {
    editorialPage(channelType: Web, pageId: $pageId) {
      pageId
      contentItems {
        ... on EditorialHeader {
          ...EditorialPageHeader
        }
        ... on EditorialOlapic {
          ...EditorialPageOlapic
        }
        ... on EditorialProductBoxCarousel {
          ...EditorialPageProductBoxCarousel
        }
        ... on EditorialBanner {
          ...EditorialPageBanner
        }
        ... on EditorialPage {
          ...EditorialPage
        }
        ... on EditorialTestimonialCarousel {
          ...TestimonialModule
        }
      }
    }
    editorialLandingPages(channelType: Web) {
      ...EditorialCategory
    }
  }
`;
