import { Button, styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(10),
  },
}));

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  gap: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8),
  },
}));

export const CarouselContainer = styled('div')({
  boxSizing: 'border-box',
  width: '100%',
});

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 'auto'),
}));
