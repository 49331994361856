import { useTheme } from '@ori-ui/mui';
import { IntroText, TopBanner } from '@ori/presentation-components';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';

import { renderOrThrowError } from '../../../utils';
import { EditorialInfo } from '../../EditorialInfo';
import { HEADER_AREA } from './constants';
import { Inner, Root } from './styles';
import type { HeaderAreaProps } from './types';

export const HeaderArea = ({ breadcrumbs, debug, header, modulePosition }: HeaderAreaProps) => {
  const theme = useTheme();
  const { getTestId } = useTestId();

  const {
    authorName,
    headline,
    text,
    imageUrl,
    photographerName,
    publishedDate,
    extraLongHeadline,
    backgroundStorybookColor,
    headlineStorybookColor,
  } = header;

  if (!headline && !imageUrl) {
    return renderOrThrowError({
      debug,
      message: 'EditorialHeader must have specified headline or imageUrl.',
      modulePosition,
      layout: 'EditorialHeader',
    });
  }

  return (
    <Root data-testid={getTestId(HEADER_AREA)}>
      <TopBanner
        breadcrumbs={breadcrumbs}
        imageUrl={imageUrl}
        title={headline}
        extraLongHeadline={extraLongHeadline}
        backgroundColor={mapBackendColorToTheme({
          theme,
          backendColor: backgroundStorybookColor,
          fallBackColor: theme.palette.common.white,
        })}
        textColor={mapBackendColorToTheme({
          theme,
          backendColor: headlineStorybookColor,
          fallBackColor: theme.palette.text.primary,
        })}
      />
      <Inner>
        <EditorialInfo
          authorName={authorName}
          photographerName={photographerName}
          publishedDate={publishedDate}
        />
        <IntroText text={text} />
      </Inner>
    </Root>
  );
};
