import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React from 'react';

import { TITLE } from './constants';
import { Root } from './styles';
import type { TitleProps } from './types';

/**
 * Returns styled title of a section. On desktop is centered.
 * Renders if `description` has thruthy value.
 */
export const Title: FC<TitleProps> = ({ title, className }) => {
  const { getTestId } = useTestId();
  if (!title) {
    return null;
  }

  return (
    <Root
      variant="h4"
      component="h4"
      color="textPrimary"
      textAlign="center"
      className={className}
      data-testid={getTestId(TITLE)}
    >
      {title}
    </Root>
  );
};
