import { TranslationsNamespaceProvider } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import type { UserSegmentationDataProviderProps } from '@ori/presentation-http';
import { UserSegmentationDataProvider } from '@ori/presentation-http';
import { TestIdProvider } from '@ori/testid-generator';
import type { FC } from 'react';

import type { EditorialPageProps } from '../components/EditorialPage';
import { EditorialPage } from '../components/EditorialPage';
import { APP_NAME, APP_TEAM_NAME, translationsNamespace } from '../constants';
import { mainLogger } from '../logger';
import { ApiProvider } from './ApiProvider';

export type EditorialPageContainerProps = EditorialPageProps &
  Omit<UserSegmentationDataProviderProps, 'visitorSegment'>;

/**
 * Component that wraps `EditorialPage` with relevant Providers (TestIdProvider, ThemeProvider, TranslationsProvider).
 */
export const EditorialPageContainer: FC<EditorialPageContainerProps> = ({
  breadcrumbs,
  data,
  hideRating,
  className,
  atpClusterId,
  customerTypeId,
  preview,
}) => (
  <TestIdProvider
    team={APP_TEAM_NAME}
    project={APP_NAME}
  >
    <LoggerProvider logger={mainLogger}>
      <ErrorBoundary areaName="EditorialPageContainer">
        <ApiProvider>
          <TranslationsNamespaceProvider namespace={translationsNamespace}>
            <UserSegmentationDataProvider
              atpClusterId={atpClusterId}
              customerTypeId={customerTypeId}
            >
              <EditorialPage
                breadcrumbs={breadcrumbs}
                data={data}
                hideRating={hideRating}
                className={className}
                preview={preview}
              />
            </UserSegmentationDataProvider>
          </TranslationsNamespaceProvider>
        </ApiProvider>
      </ErrorBoundary>
    </LoggerProvider>
  </TestIdProvider>
);
