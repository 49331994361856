import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '100%',
  gap: theme.spacing(3),
  margin: theme.spacing(0, 'auto'),
  [theme.breakpoints.up('md')]: {
    maxWidth: 984,
    gap: theme.spacing(6),
  },
}));

export const TitleWrapper = styled('div')(() => ({
  maxWidth: 600,
}));
