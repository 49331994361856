import { useTheme } from '@ori-ui/mui';
import type { ImageSizesHelperParams } from '@ori/image-sizes-helper';
import { generateSrcSet, getImageVariants } from '@ori/image-sizes-helper';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { LayoutWrapper, Root, StyledImage } from './styles';
import type { ImageBoxProps } from './types';

export const ImageBox: FC<ImageBoxProps> = (props) => {
  const { module, imageParams, sizes } = props;
  const { getTestId } = useTestId();
  const { palette } = useTheme();

  const imageUrl = module?.backgroundImage?.url ?? '';
  const imagesVariants = useMemo<ImageSizesHelperParams | null>(
    () =>
      imageUrl
        ? {
            url: imageUrl,
            imageSizeMultiplier: 1,
            backgroundColor: palette.common.white,
            imageFormat: 'WebP',
            params: imageParams ?? [],
          }
        : null,
    [imageParams, imageUrl, palette.common.white],
  );

  if (module == null) {
    return null;
  }

  const { content, backgroundImage } = module;

  if (!content && !backgroundImage) {
    return null;
  }

  return (
    <Root data-testid="image-box">
      <LayoutWrapper>
        <StyledImage
          alt={content?.title ?? ''}
          srcSet={imageParams && imagesVariants ? generateSrcSet(getImageVariants(imagesVariants)) : imageUrl}
          sizes={sizes}
          data-testid={getTestId('background-image')}
        />
      </LayoutWrapper>
    </Root>
  );
};
