import { styled } from '@ori-ui/mui';
import { textWithHtmlClasses } from '@ori/presentation-components';

import { Title } from '../shared';
import { IMAGE_DESKTOP_WIDTH } from './constants';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4, 0),
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '50%',
  },
}));

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 984,
    flexDirection: 'row',
    gap: 0,
    margin: '0 auto',
  },
}));

export const OneMediaRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('md')]: {
    gap: 0,
  },
}));
export const ImageWrapper = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: IMAGE_DESKTOP_WIDTH,
  },
}));
export const TextWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(4),
  [`.${textWithHtmlClasses.text}`]: {
    fontSize: '1.4rem',
    padding: theme.spacing(0, 3),
  },
  marginLeft: 0,
  overflow: 'hidden',
  letterSpacing: 0,

  [theme.breakpoints.up('md')]: {
    width: 550,
    marginLeft: theme.spacing(16),
    gap: theme.spacing(3),
    [`.${textWithHtmlClasses.text}`]: {
      padding: 0,
    },
  },
}));

export const TitleWithDesktopMargin = styled(Title)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 3),
  },
}));
