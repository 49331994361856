import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}));

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(0, 3),
}));
