import { styled } from '@ori-ui/mui';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: theme.spacing(12),
  },
  [theme.breakpoints.between('sm', 'md')]: {
    flexDirection: 'row',
    gap: theme.spacing(6),
  },
}));

export const ImagesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(3),
  },
}));

export const LargeImage = styled('img')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    gridArea: '1 / 1 / 2 / 5',
  },
}));

export const SmallImageLeft = styled('img')(({ theme }) => ({
  width: 'auto',
  margin: theme.spacing(0, 6, 0, 6),
  [theme.breakpoints.up('sm')]: {
    gridArea: '2 / 1 / 3 / 3',
    margin: 0,
  },
}));

export const SmallImageRight = styled('img')(({ theme }) => ({
  width: 'auto',
  margin: theme.spacing(-2, 6, 0, 6),
  [theme.breakpoints.up('sm')]: {
    gridArea: '2 / 3 / 3 / 5',
    margin: 0,
  },
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  marginTop: 0,
  fontSize: '1.4rem',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '50%',
  },
}));
