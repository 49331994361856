import { styled, Typography } from '@ori-ui/mui';
import { shouldNotForwardProp } from '@ori/presentation-utils';

export const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
export const Headline = styled(Typography, {
  shouldForwardProp: shouldNotForwardProp(['maxLines']),
})<{ maxLines: number }>(({ theme, maxLines }) => ({
  color: theme.palette.text.primary,
  textAlign: 'center',
  maxWidth: 'none',
  width: '100%',
  display: '-webkit-box',
  WebkitLineClamp: maxLines,
  lineClamp: maxLines,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    maxWidth: 860,
  },
}));
