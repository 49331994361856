import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { Button, ImageBox, Title } from '../shared';
import { LEFT_IMAGE_PARAMS, RIGHT_IMAGE_PARAMS } from './constants';
import { LeftSide, RightImage, RightSide, Root } from './styles';

export const TwoMediaRightTopTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { modules, layout, id } = banner;
  const { getTestId } = useTestId();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  const [leftImage, rightImageTitle, rightImage] = modules;
  const { title, titleColor } = rightImageTitle?.content ?? {};

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.TwoMediaRightTopText)}>
      <LeftSide>
        <ImageBox
          id={id}
          layout={layout}
          module={leftImage}
          moduleNumber={0}
          imageParams={LEFT_IMAGE_PARAMS.params}
          sizes={LEFT_IMAGE_PARAMS.sizes}
        />
        {leftImage?.link ? <Button link={leftImage.link} /> : null}
      </LeftSide>
      <RightSide>
        <Title
          maxLines={4}
          color={titleColor}
          title={title ?? ''}
          titleVariant="h5"
        />
        <RightImage>
          <ImageBox
            id={id}
            layout={layout}
            module={rightImage}
            moduleNumber={2}
            imageParams={RIGHT_IMAGE_PARAMS.params}
            sizes={RIGHT_IMAGE_PARAMS.sizes}
          />
        </RightImage>
        {rightImage?.link ? <Button link={rightImage.link} /> : null}
      </RightSide>
    </Root>
  );
};
