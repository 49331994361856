import { Alert, AlertTitle } from '@ori-ui/mui';
import { ErrorBoundary as Root, useLogger } from '@ori/logger';
import type { ErrorBoundaryLogProps } from '@ori/presentation-components';
import type { FC } from 'react';
import { useCallback } from 'react';

import { alertStyle, alertTitleStyle } from './styles';
import type { ErrorBoundaryProps } from './types';

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ debug, modulePosition, children, module }) => {
  const localLogger = useLogger('ErrorBoundary', { module, modulePosition });
  const fallback = useCallback(
    ({ error }: Partial<ErrorBoundaryLogProps>) => (
      <Alert
        severity="error"
        sx={alertStyle}
        variant="outlined"
      >
        <AlertTitle sx={alertTitleStyle}>Error</AlertTitle>
        <strong>Module:</strong> {module ?? 'Unknown'}
        <br />
        <strong>Position:</strong> {modulePosition + 1}
        <br />
        <strong>Reason:</strong> {error?.message}
      </Alert>
    ),
    [module, modulePosition],
  );

  return (
    <Root
      fallback={debug ? fallback : null}
      logger={localLogger}
    >
      {children}
    </Root>
  );
};
