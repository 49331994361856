import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import type { FC } from 'react';
import { useCallback, useState } from 'react';

import type { SharedComponentProps } from '../../../common';
import { getKey, transformHtmlContent } from '../../../utils';
import { Button } from '../Button';
import { Title } from '../Title';
import { ReadMoreLess } from './ReadMoreLess';
import { elementsProps, PARAGRAPH_FIRST_LOAD } from './constants';
import { Root, StyledTextWithHtml, TextWrapper } from './styles';

export const TextBox: FC<SharedComponentProps> = ({ layout, module, textMaxLines, titleMaxLines, titleVariant }) => {
  const [paragraphsLoaded, setParagraphsLoaded] = useState(PARAGRAPH_FIRST_LOAD);
  const [viewAll, setViewAll] = useState(false);

  const toggleReadMoreOrLess = useCallback(
    (textCollectionLength: number | undefined) => () => {
      setViewAll((prevState) => !prevState);
      setParagraphsLoaded(viewAll ? PARAGRAPH_FIRST_LOAD : (textCollectionLength ?? PARAGRAPH_FIRST_LOAD));
    },
    [viewAll],
  );

  if (module == null) {
    return null;
  }

  const { content, link } = module;
  if (!content) {
    return null;
  }

  const { textCollection, title, titleStorybookColor, showReadMoreOption } = content;
  const isParagraphText = layout === EditorialBannerLayoutEnum.ParagraphText;

  return (
    <Root>
      <Title
        maxLines={titleMaxLines}
        title={title ?? ''}
        titleVariant={titleVariant}
        color={titleStorybookColor}
      />
      {textCollection && textCollection.length > 0 ? (
        <TextWrapper
          viewAll={viewAll}
          showReadMoreOption={showReadMoreOption}
        >
          {textCollection.slice(0, showReadMoreOption ? paragraphsLoaded : undefined).map((text, index) => (
            <StyledTextWithHtml
              key={getKey('text', index)}
              isOverflowVisible={isParagraphText}
              html={transformHtmlContent(text)}
              maxLines={textMaxLines}
              elementsProps={elementsProps}
            />
          ))}
        </TextWrapper>
      ) : null}
      {showReadMoreOption ? (
        <ReadMoreLess
          viewAll={viewAll}
          toggle={toggleReadMoreOrLess(textCollection?.length)}
        />
      ) : null}
      {link ? <Button link={link} /> : null}
    </Root>
  );
};
