import { styled, Typography } from '@ori-ui/mui';
import { HorizontalContentAlignment } from '@ori/editorial-fetching';
import { shouldForwardProp } from '@ori/presentation-utils';

export const Root = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<{ backgroundColor: string }>(({ backgroundColor }) => ({
  background: backgroundColor,
  width: '100vw',
  marginLeft: 'calc(-50vw + 50%)',
}));

export const Caption = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: '2.4px',
  fontSize: '1.2rem',
  marginBottom: 0,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('md')]: {
    padding: 0,
    marginBottom: theme.spacing(-1),
  },
}));

export const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(3),
  gap: theme.spacing(4, 0),

  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(0, 8),
    flexDirection: 'row',
    maxWidth: 1366,
    height: 700,
  },
}));

export const VideoArea = styled('div', { shouldForwardProp: shouldForwardProp() })<{
  alignment: HorizontalContentAlignment;
}>(({ theme, alignment }) => ({
  order: alignment === HorizontalContentAlignment.Right || alignment === HorizontalContentAlignment.Undefined ? 1 : 0,
  iframe: {
    aspectRatio: '9 / 16 !important',
    width: '100%',
    maxWidth: 330,
  },
  margin: '0 auto',

  [theme.breakpoints.up('md')]: {
    display: 'block',
    alignSelf: 'center',
    margin: 0,
  },
}));

export const ContentArea = styled('div')(({ theme }) => ({
  display: 'flex',
  order: 2,
  flexDirection: 'column',
  width: '100%',
  maxWidth: 'unset',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    order: 'unset',
    maxWidth: 583,
    gap: theme.spacing(3),
  },
}));

export const Description = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('md')]: {
    padding: 0,
  },
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0, 0, 3),
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(3, 0, 0, 0),
  },
}));
