import { useTheme } from '@ori-ui/mui';
import { ArticlesSection, CategoriesSection } from '@ori/editorial-components';
import type { ArticlesSectionProps } from '@ori/editorial-components';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';
import { useCallback, type FC } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { LandingPageSkeleton } from '../LandingPageSkeleton';
import { LANDING_PAGE } from './constants';
import { landingPageClasses } from './landingPageClasses';
import { Description, Root, TopBanner } from './styles';
import type { LandingPageProps } from './types';

/**
 * Component that renders content on editorial landing page.
 */
export const LandingPage: FC<LandingPageProps> = ({ breadcrumbs, data, articlesProps, categoriesProps, className }) => {
  const theme = useTheme();
  const { translations, formatMessage } = useTranslations();
  const { getTestId } = useTestId();
  const { editorialLandingPage, editorialLandingPages } = data?.application ?? {};
  const { mode = 'featured', title: articlesTitle = translations.inspirationCorner } = articlesProps ?? {};
  const { title: categoriesTitle = translations.categories } = categoriesProps ?? {};
  const getPaginationInfoText = useCallback<NonNullable<ArticlesSectionProps['getPaginationInfoText']>>(
    (value, max) => formatMessage('showingXOutOfYProducts', { value, max }),
    [formatMessage],
  );
  if (!editorialLandingPage) {
    return (
      <LandingPageSkeleton
        className={className}
        articlesMode={mode}
      />
    );
  }
  const { themeTag, description, editorialItems, imageUrl, backgroundStorybookColor, headlineStorybookColor } =
    editorialLandingPage;

  return (
    <Root
      data-testid={getTestId(LANDING_PAGE)}
      className={className}
    >
      <TopBanner
        breadcrumbs={breadcrumbs}
        imageUrl={imageUrl}
        backgroundColor={mapBackendColorToTheme({
          theme,
          backendColor: backgroundStorybookColor,
          fallBackColor: theme.palette.common.white,
        })}
        textColor={mapBackendColorToTheme({
          theme,
          backendColor: headlineStorybookColor,
          fallBackColor: theme.palette.text.primary,
        })}
        title={themeTag}
        className={landingPageClasses.topBanner}
      />
      <Description
        description={description}
        className={landingPageClasses.description}
      />
      <CategoriesSection
        categories={editorialLandingPages}
        title={categoriesTitle}
        viewButtonText={translations.view}
        className={landingPageClasses.categoriesSection}
      />
      <ArticlesSection
        articles={editorialItems}
        countOfArticles={editorialItems?.length}
        labelRead={translations.readNow}
        mode={mode}
        loadMoreButtonText={translations.loadMore}
        title={articlesTitle}
        className={landingPageClasses.articlesSection}
        getPaginationInfoText={getPaginationInfoText}
      />
    </Root>
  );
};
