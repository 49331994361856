import { generateUtilityClasses } from '@ori-ui/mui';

interface CategoryPageClasses {
  /** Styles applied to the description element. */
  description: string;
  /** Styles applied to the top banner element. */
  topBanner: string;
  /** Styles applied to the categories section element. */
  categoriesSection: string;
  /** Styles applied to the articles section element. */
  articlesSection: string;
}

type CategoryPageClassKey = keyof CategoryPageClasses;

export const categoryPageClasses = generateUtilityClasses<CategoryPageClassKey>('CategoryPage', [
  'description',
  'topBanner',
  'categoriesSection',
  'articlesSection',
]);
