import type { SkeletonProps } from '@ori/editorial-components';
import { ArticlesSectionSkeleton, CategoriesSectionSkeleton } from '@ori/editorial-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { categoryPageClasses } from '../CategoryPage/categoryPageClasses';
import { Root } from '../LandingPage/styles';
import { TopBannerSkeleton, DescriptionSkeleton } from '../LandingPageSkeleton/styles';
import { CATEGORY_PAGE_SKELETON } from './constants';

/**
 * Renders Skeleton for `CategoryPage`.
 */
export const CategoryPageSkeleton: FC<SkeletonProps> = ({ animation = 'pulse', className }) => {
  const { getTestId } = useTestId();

  return (
    <Root
      className={className}
      data-testid={getTestId(CATEGORY_PAGE_SKELETON)}
    >
      <TopBannerSkeleton
        animation={animation}
        className={categoryPageClasses.topBanner}
      />
      <DescriptionSkeleton
        animation={animation}
        className={categoryPageClasses.description}
      />
      <ArticlesSectionSkeleton
        animation={animation}
        mode="listing"
        className={categoryPageClasses.articlesSection}
      />
      <CategoriesSectionSkeleton
        animation={animation}
        className={categoryPageClasses.categoriesSection}
      />
    </Root>
  );
};
