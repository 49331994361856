import { styled } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { FlexPosition } from './types';

export const Root = styled('div', { shouldForwardProp: shouldForwardProp() })<{ position: FlexPosition }>(
  ({ position }) => ({
    display: 'flex',
    justifyContent: position,
  }),
);
