import { CATEGORY_BOX_SKELETON, CategoryBoxSkeleton } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import times from 'lodash.times';
import type { FC } from 'react';

import { Root } from '../CategoriesSection/styles';
import { TitleSkeleton } from '../TitleSkeleton';
import type { SkeletonProps } from '../types';
import { CATEGORIES_SECTION_SKELETON } from './constants';
import { Categories } from './styles';

/**
 * Renders Skeleton for `CategoriesSection`.
 */
export const CategoriesSectionSkeleton: FC<SkeletonProps> = ({ animation = 'pulse', className }) => {
  const { getTestId } = useTestId();

  return (
    <Root
      className={className}
      data-testid={getTestId(CATEGORIES_SECTION_SKELETON)}
    >
      <TitleSkeleton animation={animation} />
      <Categories>
        {times(5).map((_, index) => (
          <CategoryBoxSkeleton
            // eslint-disable-next-line react/no-array-index-key -- No other option for passing data to key
            key={`${CATEGORY_BOX_SKELETON}-${index}`}
            animation={animation}
          />
        ))}
      </Categories>
    </Root>
  );
};
