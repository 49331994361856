import { styled } from '@ori-ui/mui';
import { BackgroundType, EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { SharedWrapperProps } from '../../../common';
import { getPosition } from '../../../utils';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: 'auto',
  '&.text': {
    margin: theme.spacing(6, 2),
  },
  [theme.breakpoints.up('sm')]: {
    width: '100%',
    '&.text': {
      margin: 0,
    },
  },
}));

export const StyledImage = styled('img')({
  width: '100%',
  height: 'auto',
});

export const LayoutWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  maxHeight: '100%',
});

export const ContentWrapper = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<SharedWrapperProps>(({ alignment, backgroundType }) => {
  const calculated = getPosition(alignment);

  return {
    position: 'absolute',
    padding: 0,
    alignItems: backgroundType === BackgroundType.None ? 'flex-end' : calculated.horizontalAlignment,
    justifyContent: calculated.verticalAlignment,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    textAlign: 'center',
  };
});

export const InnerContentWrapper = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<SharedWrapperProps>(({ theme, alignment, layout, hasTitle }) => {
  const calculated = getPosition(alignment);

  return {
    display: 'flex',
    position: 'relative',
    margin: hasTitle ? theme.spacing(6, 6, 0, 6) : theme.spacing(12, 6, 0, 6),
    alignItems:
      layout === EditorialBannerLayoutEnum.OneMediaOverlayLeftText ? 'center' : calculated.horizontalAlignment,
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
  };
});
