import { generateUtilityClasses } from '@ori-ui/mui';

interface EditorialPageClasses {
  /** Styles applied to the root element. */
  root: string;
  /** Styles applied to the inner element. */
  inner: string;
  /** Styles applied to the categories section element. */
  categoriesSection: string;
  /** Styles applied to the editorial carousel root element. */
  editorialCarouselRoot: string;
}

type EditorialPageClassKey = keyof EditorialPageClasses;

export const editorialPageClasses = generateUtilityClasses<EditorialPageClassKey>('EditorialPage', [
  'categoriesSection',
  'editorialCarouselRoot',
  'inner',
  'root',
]);
