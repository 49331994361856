import { generateUtilityClasses } from '@ori-ui/mui';

interface LandingPageClasses {
  /** Styles applied to the description element. */
  description: string;
  /** Styles applied to the top banner element. */
  topBanner: string;
  /** Styles applied to the categories section element. */
  categoriesSection: string;
  /** Styles applied to the articles section element. */
  articlesSection: string;
}

type LandingPageClassKey = keyof LandingPageClasses;

export const landingPageClasses = generateUtilityClasses<LandingPageClassKey>('LandingPage', [
  'description',
  'topBanner',
  'categoriesSection',
  'articlesSection',
]);
