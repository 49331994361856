import { styled, Typography, type TypographyProps } from '@ori-ui/mui';
import { shouldNotForwardProp } from '@ori/presentation-utils';

import type { TitleProps } from './types';

export const DefaultTitle = styled(Typography, { shouldForwardProp: shouldNotForwardProp(['maxLines']) })<
  Pick<TitleProps, 'maxLines'> & Pick<TypographyProps, 'component'>
>(({ theme, maxLines }) => ({
  margin: theme.spacing(0, 3),
  display: '-webkit-box',
  WebkitLineClamp: maxLines,
  lineClamp: maxLines,
  overflow: 'hidden',
  textAlign: 'center',
  WebkitBoxOrient: 'vertical',
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },
}));
