import { Alert, Typography, useTheme } from '@ori-ui/mui';
import type { ArticlesSectionProps } from '@ori/editorial-components';
import { ArticlesSection, CategoriesSection } from '@ori/editorial-components';
import { mapBackendColorToTheme } from '@ori/presentation-utils';
import useTestId from '@ori/testid-generator';
import { useCallback, type FC } from 'react';

import { useTranslations } from '../../hooks/useTranslations';
import { CategoryPageSkeleton } from '../CategoryPageSkeleton';
import { Description, Root, TopBanner } from '../LandingPage/styles';
import { categoryPageClasses } from './categoryPageClasses';
import { CATEGORY_PAGE } from './constants';
import type { CategoryPageProps } from './types';

/**
 * Component that renders content on editorial category page.
 */
export const CategoryPage: FC<CategoryPageProps> = ({ breadcrumbs, data, preview, className }) => {
  const theme = useTheme();
  const { formatMessage, translations } = useTranslations();
  const { getTestId } = useTestId();
  const getPaginationInfoText = useCallback<NonNullable<ArticlesSectionProps['getPaginationInfoText']>>(
    (value, max) => formatMessage('showingXOutOfYProducts', { value, max }),
    [formatMessage],
  );

  const { editorialLandingPage, editorialLandingPages } = data ?? {};
  if (!editorialLandingPage) {
    return <CategoryPageSkeleton className={className} />;
  }
  const { themeTag, description, editorialItems, imageUrl, backgroundStorybookColor, headlineStorybookColor } =
    editorialLandingPage;

  return (
    <Root
      data-testid={getTestId(CATEGORY_PAGE)}
      className={className}
    >
      {preview ? (
        <Alert
          color="success"
          severity="info"
        >
          <Typography>PREVIEW MODE</Typography>
        </Alert>
      ) : null}

      <TopBanner
        breadcrumbs={breadcrumbs}
        imageUrl={imageUrl}
        backgroundColor={mapBackendColorToTheme({
          theme,
          backendColor: backgroundStorybookColor,
          fallBackColor: theme.palette.common.white,
        })}
        textColor={mapBackendColorToTheme({
          theme,
          backendColor: headlineStorybookColor,
          fallBackColor: theme.palette.text.primary,
        })}
        title={themeTag}
        className={categoryPageClasses.topBanner}
      />
      <Description
        description={description}
        className={categoryPageClasses.description}
      />
      <ArticlesSection
        articles={editorialItems}
        countOfArticles={editorialItems?.length}
        labelRead={translations.readNow}
        loadMoreButtonText={translations.loadMore}
        mode="listing"
        title=""
        getPaginationInfoText={getPaginationInfoText}
        className={categoryPageClasses.articlesSection}
      />
      <CategoriesSection
        categories={editorialLandingPages}
        title={translations.otherCategories}
        viewButtonText={translations.view}
        className={categoryPageClasses.categoriesSection}
      />
    </Root>
  );
};
