import { TranslationsNamespaceProvider } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import { TestIdProvider } from '@ori/testid-generator';
import type { FC } from 'react';

import type { CategoryPageProps } from '../components';
import { CategoryPage } from '../components';
import { APP_NAME, APP_TEAM_NAME, translationsNamespace } from '../constants';
import { mainLogger } from '../logger';

export type CategoryPageContainerProps = CategoryPageProps;

/**
 * Component that wraps `CategoryPage` with relevant Providers (TestIdProvider, ThemeProvider, TranslationsProvider).
 */
export const CategoryPageContainer: FC<CategoryPageContainerProps> = ({ className, breadcrumbs, data, preview }) => (
  <TestIdProvider
    team={APP_TEAM_NAME}
    project={APP_NAME}
  >
    <LoggerProvider logger={mainLogger}>
      <ErrorBoundary areaName="EditorialCategoryPageContainer">
        <TranslationsNamespaceProvider namespace={translationsNamespace}>
          <CategoryPage
            className={className}
            breadcrumbs={breadcrumbs}
            data={data}
            preview={preview}
          />
        </TranslationsNamespaceProvider>
      </ErrorBoundary>
    </LoggerProvider>
  </TestIdProvider>
);
