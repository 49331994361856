import { StarRating as StarRatingComponent, starRatingClasses } from '@ori-ui/components';
import { styled, Typography } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { HasImageProp } from './types';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    backgroundColor: theme.palette.grey[100],
    maxWidth: 1366,
    height: 385,
  },
}));

export const ContentWrapper = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<HasImageProp>(({ theme, hasImage }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  order: 2,
  margin: 0,
  [theme.breakpoints.up('sm')]: {
    order: 1,
    alignItems: 'center',
    gap: theme.spacing(4),
    width: hasImage ? '50%' : '100%',
    margin: theme.spacing(8),
  },
}));

export const ContentInner = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<HasImageProp>(({ theme, hasImage }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(3),
  height: hasImage ? 360 : 320,
  margin: theme.spacing(4, 3),
  [theme.breakpoints.up('sm')]: {
    height: 'auto',
    gap: theme.spacing(4),
  },
}));

export const StarRating = styled(StarRatingComponent)(() => ({
  [`& .${starRatingClasses.star}`]: {
    fontSize: '1.4rem',
    margin: 0,
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth: '50%',
    order: 2,
  },
}));

export const Image = styled('img')(({ theme }) => ({
  height: 'auto',
  [theme.breakpoints.up('sm')]: {
    objectFit: 'cover',
    height: 385,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  letterSpacing: '2.4px',
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.secondary,
}));
export const Description = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'center',
  WebkitLineClamp: 7,
  lineHeight: '1.5',
  fontWeight: theme.typography.fontWeightBold,
}));
export const NameAndCountry = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
