import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { TextBox } from '../shared';
import { LARGE_IMAGE, SMALL_IMAGE_LEFT, SMALL_IMAGE_RIGHT } from './constants';
import { ContentWrapper, ImagesWrapper, LargeImage, Root, SmallImageLeft, SmallImageRight } from './styles';

export const ThreeMediaRightBottomTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const isMobile = useIsMobile();
  const { modules, layout, id } = banner;
  const { getTestId } = useTestId();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  const [largeImage, smallImageLeft, smallImageRight, textArea] = modules;

  const textComponent = (
    <ContentWrapper>
      <TextBox
        id={id}
        layout={EditorialBannerLayoutEnum.ThreeMediaRightBottomText}
        module={textArea}
        titleMaxLines={isMobile ? 5 : 4}
        moduleNumber={3}
      />
    </ContentWrapper>
  );

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.ThreeMediaRightBottomText)}>
      <ImagesWrapper>
        <LargeImage
          data-testid={getTestId(LARGE_IMAGE)}
          src={largeImage?.backgroundImage?.url ?? undefined}
        />
        {isMobile ? textComponent : null}
        <SmallImageLeft
          data-testid={getTestId(SMALL_IMAGE_LEFT)}
          src={smallImageLeft?.backgroundImage?.url ?? undefined}
        />
        <SmallImageRight
          data-testid={getTestId(SMALL_IMAGE_RIGHT)}
          src={smallImageRight?.backgroundImage?.url ?? undefined}
        />
      </ImagesWrapper>
      {isMobile ? null : textComponent}
    </Root>
  );
};
