/* istanbul ignore file */
import { mapProductBoxesFromStaticApi, ProductBoxMode } from '@ori/product-box';
import type { ApiClient, GetTenantApplicationsProductBoxParams } from '@ori/static-api-client-react';
import { getTenantApplicationsProductBox } from '@ori/static-api-client-react';

export type FetchParams = Pick<
  GetTenantApplicationsProductBoxParams,
  'atpClusterId' | 'customerTypeId' | 'lang' | 'visitorSegment'
>;

export interface GetEditorialProductsParams {
  productCodes: string[];
  fetchParams: FetchParams;
  staticApiClient: ApiClient;
  tenant: string;
}

export const getEditorialProducts = async ({
  fetchParams,
  productCodes,
  staticApiClient,
  tenant,
}: GetEditorialProductsParams) => {
  const {
    data: { data },
  } = await getTenantApplicationsProductBox(staticApiClient, tenant, { ...fetchParams, productCodes });
  const { products } = data ?? {};
  if (!products) {
    return [];
  }

  return mapProductBoxesFromStaticApi(products, undefined, ProductBoxMode.Product);
};
