import { styled } from '@ori-ui/mui';
import { TextWithHtml } from '@ori/presentation-components';
import { shouldForwardProp } from '@ori/presentation-utils';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(3, 0),
  [theme.breakpoints.up('md')]: {
    maxWidth: 550,
  },
}));

export const TextWrapper = styled('div', {
  shouldForwardProp: shouldForwardProp(),
})<{ viewAll: boolean; showReadMoreOption?: boolean | null | undefined }>(({ showReadMoreOption, viewAll, theme }) => ({
  maxHeight: showReadMoreOption && !viewAll ? 240 : 'none',
  overflow: 'hidden',
  display: 'flex',
  textAlign: 'left',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 3),
  },
}));

export const ReadMore = styled('span')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'column',
  fontSize: '1.4rem',
  alignSelf: 'center',
  alignItems: 'center',
  fontWeight: theme.typography.fontWeightBold,
  '&:svg': {
    margin: theme.spacing(0, 'auto'),
    width: 24,
    height: 24,
  },
}));

export const StyledTextWithHtml = styled(TextWithHtml)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.text.secondary,

  overflow: 'hidden',
  '&:last-child': {
    marginBottom: 0,
  },
}));
