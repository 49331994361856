import { TranslationsNamespaceProvider } from '@ori/i18n';
import { ErrorBoundary, LoggerProvider } from '@ori/logger';
import { TestIdProvider } from '@ori/testid-generator';
import type { FC } from 'react';

import type { LandingPageProps } from '../components';
import { LandingPage } from '../components';
import { APP_NAME, APP_TEAM_NAME, translationsNamespace } from '../constants';
import { mainLogger } from '../logger';

export type LandingPageContainerProps = LandingPageProps;

/**
 * Component that wraps `LandingPage` with relevant Providers.
 */
export const LandingPageContainer: FC<LandingPageContainerProps> = ({
  breadcrumbs,
  data,
  articlesProps,
  className,
  categoriesProps,
}) => (
  <TestIdProvider
    team={APP_TEAM_NAME}
    project={APP_NAME}
  >
    <LoggerProvider logger={mainLogger}>
      <ErrorBoundary areaName="EditorialLandingPageContainer">
        <TranslationsNamespaceProvider namespace={translationsNamespace}>
          <LandingPage
            breadcrumbs={breadcrumbs}
            data={data}
            articlesProps={articlesProps}
            className={className}
            categoriesProps={categoriesProps}
          />
        </TranslationsNamespaceProvider>
      </ErrorBoundary>
    </LoggerProvider>
  </TestIdProvider>
);
