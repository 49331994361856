import { Alert, Typography } from '@ori-ui/mui';
import { useToken } from '@ori/auth';
import { CategoriesSection, EditorialCarouselSection, mapEditorialCarousel } from '@ori/editorial-components';
import { type EditorialPageFragment } from '@ori/editorial-fetching';
import { useEnvironment } from '@ori/environment';
import { OlapicTypeEnum } from '@ori/olapic-lib';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { ErrorBoundary } from '../../containers/ErrorBoundary';
import { useTranslations } from '../../hooks/useTranslations';
import { getEditorialDebug, getProductCodes } from '../../utils';
import { createBannerLayout } from '../../utils/createBannerLayout';
import { TestimonialsLayout } from '../TestimonialsLayout/TestimonialsLayout';
import { HeaderArea, Olapic, Products } from '../standalone';
import { EditorialPageSkeleton } from './EditorialPageSkeleton';
import { Errors } from './Errors';
import { EDITORIAL_PAGE } from './constants';
import { editorialPageClasses } from './editorialPageClasses';
import { EditorialCarouselRoot, Inner, Root } from './styles';
import type { EditorialPageProps } from './types';

export const EditorialPage: FC<EditorialPageProps> = ({ breadcrumbs, data, hideRating, className, preview }) => {
  const { tenant } = useEnvironment();
  const { translations } = useTranslations();
  const { getTestId } = useTestId();
  const { customerId } = useToken();
  const debug = getEditorialDebug();

  // istanbul ignore next -- reachable only in preview mode
  if (!data) {
    return <EditorialPageSkeleton className={className} />;
  }

  const relatedArticles = (data.editorialPage?.contentItems ?? []).filter(
    (module): module is EditorialPageFragment => module.__typename === 'EditorialPage',
  );

  const topBanner = data.editorialPage?.contentItems.find((module) => module.__typename === 'EditorialHeader');

  const layouts = data.editorialPage?.contentItems.map((module, modulePosition) => {
    switch (module.__typename) {
      case 'EditorialOlapic':
        return (
          <ErrorBoundary
            key={module.id}
            debug={debug}
            module={module.__typename}
            modulePosition={modulePosition}
          >
            <Olapic
              key="olapic"
              type={module.type ?? OlapicTypeEnum.FrontPage}
              categoryId={module.categoryId ?? undefined}
              productCode={module.product?.productCode}
            />
          </ErrorBoundary>
        );
      case 'EditorialTestimonialCarousel':
        return (
          <ErrorBoundary
            key={module.id}
            debug={debug}
            module={module.__typename}
            modulePosition={modulePosition}
          >
            <TestimonialsLayout
              data={module}
              debug={debug}
              modulePosition={modulePosition}
            />
          </ErrorBoundary>
        );
      case 'EditorialProductBoxCarousel':
        return (
          <ErrorBoundary
            // eslint-disable-next-line react/no-array-index-key -- No other suitable key
            key={`${module.__typename}-${modulePosition}`}
            debug={debug}
            module={module.__typename}
            modulePosition={modulePosition}
          >
            <Products
              key="product-carousel"
              debug={debug}
              modulePosition={modulePosition}
              customerId={customerId}
              hideRating={hideRating}
              headline={module.headline}
              productCodes={getProductCodes(module.products)}
              tenant={tenant}
              id={`editorials-product-carousel-${modulePosition}`}
            />
          </ErrorBoundary>
        );
      case 'EditorialBanner':
        return (
          <ErrorBoundary
            key={module.id}
            debug={debug}
            module={module.layout}
            modulePosition={modulePosition}
          >
            {createBannerLayout({
              banner: module,
              debug,
              modulePosition,
            })}
          </ErrorBoundary>
        );
      case 'EditorialPage':
        return null;
      default:
        return (
          <ErrorBoundary
            // eslint-disable-next-line react/no-array-index-key -- No other suitable key
            key={`unknownTypename-${modulePosition}`}
            debug={debug}
            module={module.__typename}
            modulePosition={modulePosition}
          >
            <Errors.UnknownTypename
              debug={debug}
              modulePosition={modulePosition}
              layout={module.__typename}
            />
          </ErrorBoundary>
        );
    }
  });

  return (
    <>
      {topBanner ? (
        <ErrorBoundary
          debug={debug}
          module={topBanner.__typename}
          modulePosition={0}
        >
          <HeaderArea
            key="header-area"
            breadcrumbs={breadcrumbs}
            debug={debug}
            header={topBanner}
            modulePosition={0}
          />
        </ErrorBoundary>
      ) : null}
      <Root
        data-testid={getTestId(EDITORIAL_PAGE)}
        className={className}
      >
        {preview ? (
          <Alert
            color="success"
            severity="info"
          >
            <Typography>PREVIEW MODE</Typography>
          </Alert>
        ) : null}

        <Inner className={editorialPageClasses.inner}>{layouts}</Inner>
        <EditorialCarouselRoot className={editorialPageClasses.editorialCarouselRoot}>
          <EditorialCarouselSection
            data={mapEditorialCarousel({ slides: relatedArticles })}
            labelRead={translations.readNow}
            title={translations.inspirationCorner}
            id="editorial-page-related-articles-carousel"
          />
        </EditorialCarouselRoot>
        <CategoriesSection
          categories={data.editorialLandingPages}
          title={translations.otherCategories}
          viewButtonText={translations.view}
          className={editorialPageClasses.categoriesSection}
        />
      </Root>
    </>
  );
};
