import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import React from 'react';

import { DefaultTitle } from './styles';
import type { TitleProps } from './types';

export const Title = ({ maxLines, titleVariant, title, className }: TitleProps) => {
  const { getTestId } = useTestId();
  const isMobile = useIsMobile();

  if (!title) {
    return null;
  }

  const variantComponent = titleVariant ?? (isMobile ? 'h5' : 'h4');

  return (
    <DefaultTitle
      component={variantComponent}
      className={className}
      data-testid={getTestId('title')}
      maxLines={maxLines}
      variant={variantComponent}
    >
      {title}
    </DefaultTitle>
  );
};
