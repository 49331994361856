import { styled, Typography, type TypographyProps } from '@ori-ui/mui';
import { shouldForwardProp } from '@ori/presentation-utils';

import type { QuoteTextProps } from './types';

const DEFAULT_HEIGHT = 250;
const DEFAULT_HEIGHT_MOBILE = 210;

export const Root = styled('div', {
  shouldForwardProp: shouldForwardProp(['height']),
})<Pick<QuoteTextProps, 'backgroundColor' | 'titleColor'>>(({ backgroundColor, titleColor, theme }) => ({
  backgroundColor,
  color: titleColor,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
  justifyContent: 'center',
  height: DEFAULT_HEIGHT_MOBILE,
  padding: 0,
  [theme.breakpoints.up('sm')]: {
    height: DEFAULT_HEIGHT,
    padding: theme.spacing(2, 0),
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
  },
}));

export const Text = styled(Typography)<Pick<TypographyProps, 'variant' | 'component'>>(({ theme, variant }) => ({
  width: '100%',
  display: '-webkit-box',
  WebkitLineClamp: variant === 'h5' ? 6 : 7,
  lineClamp: variant === 'h5' ? 6 : 7,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  maxHeight: DEFAULT_HEIGHT_MOBILE,
  color: theme.palette.text.primary,
  margin: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    width: 600,
    margin: 0,
  },
}));
