import { ArticlesSectionSkeleton, CategoriesSectionSkeleton } from '@ori/editorial-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { landingPageClasses } from '../LandingPage/landingPageClasses';
import { Root } from '../LandingPage/styles';
import { LANDING_PAGE_SKELETON } from './constants';
import { TopBannerSkeleton, DescriptionSkeleton } from './styles';
import type { LandingPageSkeletonProps } from './types';

/**
 * Renders Skeleton for `LandingPage`.
 *
 */
export const LandingPageSkeleton: FC<LandingPageSkeletonProps> = ({
  animation = 'pulse',
  articlesMode = 'featured',
  className,
}) => {
  const { getTestId } = useTestId();

  return (
    <Root
      className={className}
      data-testid={getTestId(LANDING_PAGE_SKELETON)}
    >
      <TopBannerSkeleton
        animation={animation}
        className={landingPageClasses.topBanner}
      />
      <DescriptionSkeleton
        animation={animation}
        className={landingPageClasses.description}
      />
      <CategoriesSectionSkeleton
        animation={animation}
        className={landingPageClasses.categoriesSection}
      />
      <ArticlesSectionSkeleton
        showTitle
        animation={animation}
        mode={articlesMode}
        className={landingPageClasses.articlesSection}
      />
    </Root>
  );
};
