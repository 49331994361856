export const LINK_TYPE_PRODUCT = 'Product';

export const PAGE_ID_PATH = 'campaigns/editorials';

export const YOUTUBE_MEDIA_URL = 'https://www.youtube.com/embed/';
export const YOUKU_MEDIA_URL = 'https://player.youku.com/embed/';

export const APP_NAME = 'editorial-page';
export const APPLICATION_NAME = `@ori/${APP_NAME}`;

export enum OverlayPosition {
  Left = 'left',
  Right = 'right',
}
