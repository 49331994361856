import type { EditorialLandingPage } from '../models';

export const categories: EditorialLandingPage[] = [
  {
    landingPageId: '/campaigns/editorials/testtheme',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=590b4808-9e13-4552-b612-7b93810bb22e&name=skincare&inputFormat=png',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=856697f4-5936-4c92-8228-a017337d21a8&name=SkincareSunZoneUvProtectorFaceExposedAreasSpf50HighTheOne5in1ColourStylistLipstickGarnetAttraction23&inputFormat=jpg',
    description: 'Description of TestTheme',
    themeTag: 'Test Theme',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/testtheme/test1',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme/test2',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme/test3',
        contentItems: [],
      },
    ],
  },
  {
    landingPageId: '/campaigns/editorials/testtheme2',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=8bb37dbd-a30b-4c0d-a883-2b49ec665a72&name=giordanigold-superlaunch-originale&inputFormat=jpg',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=6048074f-7aea-4965-90f2-d2ae79a18ed4&name=giordani-man&inputFormat=jpg',
    description: 'Description of TestTheme2',
    themeTag: 'TestTheme2',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/testtheme2/test2-1',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme2/test2-2',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme2/test2-3',
        contentItems: [],
      },
    ],
  },
  {
    landingPageId: '/campaigns/editorials/testtheme3',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=60bc3081-4db0-48aa-a5da-5554f1aceb00&name=Love+nature_Week+3_C10_10x10_article-top&inputFormat=png',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=50fc3952-58b9-4adb-ad5d-17a3ec308839&name=Love+Nature+relaunch&inputFormat=png',
    description: 'Description TestTheme3',
    themeTag: 'TestTheme3',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/testtheme3/test3-1',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme3/test3-2',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme3/test3-3',
        contentItems: [],
      },
    ],
  },
  {
    landingPageId: '/campaigns/editorials/testtheme4',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=6755a0c7-25a7-41cd-b51c-60dcbf0538ba&name=FRAGRANCES-1&inputFormat=png',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=c3658094-9c4d-4ab1-b920-7bb916584f2e&name=fragrancegiordanigoldessenzapossessvolarelovepotioneaudetoiletteparfum301733182532150325353344833493&inputFormat=jpg',
    description: 'Description of TestTheme4',
    themeTag: 'TestTheme4',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/testtheme4/editorialpagetestingolapic',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme4/test4-1',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme4/veronikaeditorial',
        contentItems: [],
      },
    ],
  },
  {
    landingPageId: '/campaigns/editorials/wellosophy',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=71b511e9-6990-4293-aa67-b9477b98a825&name=Wellosophy_2x1&inputFormat=png',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=25009d2c-1971-48ab-8526-a3c379e27f0a&name=Wellosophy_Logo_grey&inputFormat=png',
    description: 'Description of Wellosophy',
    themeTag: 'Wellosophy',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-beauty-from-within',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-ageing',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-gut',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-living',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-in-shape',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-subscription',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-true-wellbeing-starts-here',
        contentItems: [],
      },
    ],
  },

  {
    landingPageId: '/campaigns/editorials/testtheme5',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=590b4808-9e13-4552-b612-7b93810bb22e&name=skincare&inputFormat=png',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=856697f4-5936-4c92-8228-a017337d21a8&name=SkincareSunZoneUvProtectorFaceExposedAreasSpf50HighTheOne5in1ColourStylistLipstickGarnetAttraction23&inputFormat=jpg',
    description: 'Description of TestTheme',
    themeTag: 'Test Theme 5',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/testtheme/test1',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme/test2',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme/test3',
        contentItems: [],
      },
    ],
  },
  {
    landingPageId: '/campaigns/editorials/testtheme6',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=8bb37dbd-a30b-4c0d-a883-2b49ec665a72&name=giordanigold-superlaunch-originale&inputFormat=jpg',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=6048074f-7aea-4965-90f2-d2ae79a18ed4&name=giordani-man&inputFormat=jpg',
    description: 'Description of TestTheme2',
    themeTag: 'TestTheme6',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/testtheme2/test2-1',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme2/test2-2',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme2/test2-3',
        contentItems: [],
      },
    ],
  },
  {
    landingPageId: '/campaigns/editorials/testtheme7',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=60bc3081-4db0-48aa-a5da-5554f1aceb00&name=Love+nature_Week+3_C10_10x10_article-top&inputFormat=png',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=50fc3952-58b9-4adb-ad5d-17a3ec308839&name=Love+Nature+relaunch&inputFormat=png',
    description: 'Description TestTheme3',
    themeTag: 'TestTheme7',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/testtheme3/test3-1',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme3/test3-2',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme3/test3-3',
        contentItems: [],
      },
    ],
  },
  {
    landingPageId: '/campaigns/editorials/testtheme8',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=6755a0c7-25a7-41cd-b51c-60dcbf0538ba&name=FRAGRANCES-1&inputFormat=png',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=c3658094-9c4d-4ab1-b920-7bb916584f2e&name=fragrancegiordanigoldessenzapossessvolarelovepotioneaudetoiletteparfum301733182532150325353344833493&inputFormat=jpg',
    description: 'Description of TestTheme4',
    themeTag: 'TestTheme8',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/testtheme4/editorialpagetestingolapic',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme4/test4-1',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/testtheme4/veronikaeditorial',
        contentItems: [],
      },
    ],
  },
  {
    landingPageId: '/campaigns/editorials/testtheme9',
    imageUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=71b511e9-6990-4293-aa67-b9477b98a825&name=Wellosophy_2x1&inputFormat=png',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=25009d2c-1971-48ab-8526-a3c379e27f0a&name=Wellosophy_Logo_grey&inputFormat=png',
    description: 'Description of Wellosophy',
    themeTag: 'TestTheme9',
    editorialItems: [
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-beauty-from-within',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-ageing',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-gut',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-living',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-in-shape',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-subscription',
        contentItems: [],
      },
      {
        pageId: '/campaigns/editorials/wellosophy/wellosophy-true-wellbeing-starts-here',
        contentItems: [],
      },
    ],
  },
];
