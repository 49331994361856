import { EditorialBannerLayoutEnum } from '@ori/editorial-fetching';
import useTestId from '@ori/testid-generator';

import type { BannerComponentProps } from '../../common';
import { getModulesLengthError } from '../../utils';
import { Button, ImageBox, Title } from '../shared';
import { IMAGE_PARAMS } from './constants';
import { Root, TitleWrapper } from './styles';

export const OneMediaCenterBottomTextLayout = ({ banner, debug, modulePosition }: BannerComponentProps) => {
  const { modules, layout, id } = banner;
  const { getTestId } = useTestId();

  if (!modules || modules.length < 1) {
    return getModulesLengthError({ debug, layout, modulePosition });
  }

  const { title, titleStorybookColor } = modules[0]?.content ?? {};
  const link = modules[0]?.link;

  return (
    <Root data-testid={getTestId(EditorialBannerLayoutEnum.OneMediaCenterBottomText)}>
      <ImageBox
        id={id}
        moduleNumber={0}
        module={modules[0]}
        layout={layout}
        imageParams={IMAGE_PARAMS.params}
        sizes={IMAGE_PARAMS.sizes}
      />
      {title ? (
        <TitleWrapper>
          <Title
            title={title}
            maxLines={4}
            titleVariant="h5"
            color={titleStorybookColor}
          />
        </TitleWrapper>
      ) : null}
      {link ? <Button link={link} /> : null}
    </Root>
  );
};
