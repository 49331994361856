import type { HtmlText } from '../components/shared/TextBox/types';
import { replaceLinks as createReplaceLinks } from '../components/shared/TextBox/utils';

export const transformHtmlContent = (html?: HtmlText | null) => {
  if (!html?.text) {
    return null;
  }
  const { text, textLinks } = html;
  const replaceLinks = createReplaceLinks(textLinks);

  return replaceLinks(text);
};
