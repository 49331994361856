import { styled } from '@ori-ui/mui';

import { IMAGE_DESKTOP_WIDTH } from './constants';

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'center',
  gap: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    width: 830,
    margin: 'auto',
  },
}));

export const FourMediaItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
  maxWidth: 'unset',
  [theme.breakpoints.up('md')]: {
    maxWidth: IMAGE_DESKTOP_WIDTH,
  },
}));
