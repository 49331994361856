import { styled } from '@ori-ui/mui';
import {
  CategoryPageContainer,
  LandingPageContainer,
  categoryPageClasses,
  landingPageClasses,
} from '@ori/editorial-category';
import { EditorialPageContainer } from '@ori/editorial-page';

export const StyledLandingPageContainer = styled(LandingPageContainer)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 5),
    [`.${landingPageClasses.topBanner}`]: {
      margin: theme.spacing(0, -5, 6, -5),
    },
  },
}));

export const StyledCategoryPageContainer = styled(CategoryPageContainer)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(0, 5),
    [`.${categoryPageClasses.topBanner}`]: {
      margin: theme.spacing(0, -5, 6, -5),
    },
  },
}));

export const StyledEditorialPageContainer = styled(EditorialPageContainer)(({ theme }) => ({
  background: theme.palette.background.default,
}));
