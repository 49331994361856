import { useToken } from '@ori/auth';
import { useGetEditorialProducts } from '@ori/editorial-fetching';
import { useAreFavoritesEnabled } from '@ori/presentation-hooks';
import type { MappedProductBoxProps } from '@ori/product-box';
import { ProductBox } from '@ori/product-box';
import type { HybridMode, RenderItem } from '@ori/product-list';
import { ProductList } from '@ori/product-list';
import { useCallback, useMemo } from 'react';

import { renderOrThrowError } from '../../../utils';
import { PRODUCTS } from './constants';
import { Headline, Root } from './styles';
import type { ProductsProps } from './types';

export const Products = ({
  debug,
  modulePosition,
  analyticsPlacement,
  headline,
  hideRating,
  productCodes,
  className,
}: ProductsProps) => {
  const { isLoadingMore, loadMoreItems, products, isRefreshing } = useGetEditorialProducts({ productCodes });
  const loadedItemCount = products.length;
  const totalItemCount = productCodes.length;
  const { customerId } = useToken();
  const favoritesEnabled = useAreFavoritesEnabled({ customerId });

  const renderItemComponent: RenderItem<MappedProductBoxProps> = useCallback(
    ({ item: product, index }) => (
      <ProductBox
        key={product.code}
        {...product}
        hideRating={hideRating}
        analyticsData={{
          placement: analyticsPlacement,
          position: index,
        }}
        buttons={{
          ...product.buttons,
          favorites: {
            ...product.buttons?.favorites,
            visible: favoritesEnabled,
          },
        }}
      />
    ),
    [analyticsPlacement, favoritesEnabled, hideRating],
  );

  const productListHybridModeConfig = useMemo<HybridMode>(
    () => ({
      threshold: 11,
      type: 'HYBRID',
      carousel: {
        id: `editorials-product-carousel-${modulePosition}`,
      },
    }),
    [modulePosition],
  );

  if (totalItemCount < 1) {
    return renderOrThrowError({
      debug,
      message: 'EditorialProductBoxCarousel must have at least one product.',
      modulePosition,
      layout: 'EditorialProductBoxCarousel',
    });
  }

  return (
    <Root
      className={className}
      data-testid={PRODUCTS}
      isList={totalItemCount >= productListHybridModeConfig.threshold}
    >
      {headline ? <Headline variant="h4">{headline}</Headline> : null}
      <ProductList
        items={products}
        shouldRenderItemCount={false}
        renderItemComponent={renderItemComponent}
        mode={productListHybridModeConfig}
        totalItemCount={totalItemCount}
        loadedItemCount={loadedItemCount}
        loadMoreItems={loadMoreItems}
        isLoadingMore={isLoadingMore}
        withTranslationsProvider={false}
        isRefreshing={isRefreshing}
      />
    </Root>
  );
};
