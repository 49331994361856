import { theme } from '@ori-ui/mui';
import type { ImageParams } from '@ori/image-sizes-helper';

const { sm } = theme.breakpoints.values;

export interface GetImageSizes {
  mobileWidth?: number;
  desktopWidth: number;
}
export const getImageSizes = ({ mobileWidth = sm, desktopWidth }: GetImageSizes) =>
  `(max-width: ${sm - 1}px) ${mobileWidth}px, ${desktopWidth}px`;

export const getImageParams = ({ mobileWidth = sm, desktopWidth }: GetImageSizes): ImageParams[] => [
  {
    width: desktopWidth,
    quality: 85,
  },
  {
    width: mobileWidth,
    quality: 90,
  },
];

export interface GetImageParamsAndSizes {
  params: ImageParams[];
  sizes: string;
}
export const getImageParamsAndSizes = (params: GetImageSizes): GetImageParamsAndSizes => {
  const imageParams = getImageParams(params);
  const sizes = getImageSizes(params);

  return { params: imageParams, sizes };
};
